<template>
	<div class="page-content">
		<!-- <div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<el-form-item label="标题" prop="title">
					<el-input v-model="searchForm.title" style="width: 220px;" clearable placeholder="请填写"></el-input>
				</el-form-item>
				<el-form-item label="作者" prop="author">
					<el-input v-model="searchForm.author" style="width: 220px;" clearable placeholder="请填写"></el-input>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-right: auto;">
					<div class="fill-btn" @click="getList">查询</div>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>
			</el-form>
		</div> -->
		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading" cell-class-name="text-center"
				header-cell-class-name="header-row-class-name">
				<el-table-column prop="id" label="ID" min-width="60px"></el-table-column>
				<!-- <el-table-column prop="type" label="类型" min-width="120px">
					<template slot-scope="{row}">
						{{row.type==1?'普通文本':row.type==2?'富文本':row.type==3?'数字':row.type==4?'开关':row.type==5?'文件':row.type==6?'电话':'图片'}}
					</template>
				</el-table-column> -->
				<el-table-column prop="remarks" label="配置备注" min-width="120px"></el-table-column>
				<el-table-column prop="value" label="配置内容" min-width="120px">
					<template slot-scope="{row}">
						<el-popover placement="top" trigger="click">
							<div class="box-card">
								{{row.value}}
							</div>
							<div class="line-clamp-1 pointer" slot="reference">
								{{row.value}}
							</div>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" min-width="160px" label="创建时间"></el-table-column>
				<el-table-column width="100" fixed="right">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
								@click="handEditShow(row)"></el-button>
							<!-- <el-button class="el-icon-delete cur-p" type="primary" size="mini" @click="del(row)">
							</el-button> -->
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog :title="`${editId?'编辑':'创建'}`" :visible.sync="showEdit" width="60%">
			<div style="padding: 20px;">
				<el-form ref="forms" :model="editForm" label-width="80px">
					<!-- 1 普通文本 2 富文本 3 数字 4 开关 5 文件 6 电话 7 图片 -->
					<el-form-item v-if="editForm.type==1" label="配置值" prop="value" :rules="[{required: true,message: `配置值不能为空`,trigger: ['blur', 'change']}]">
						<el-input v-model="editForm.value" type="textarea" placeholder="请输入配置值"></el-input>
					</el-form-item>
					<el-form-item v-else-if="editForm.type==2" label="配置值" prop="value" :rules="[{required: true,message: `配置值不能为空`,trigger: ['blur', 'change']}]">
						<RichText v-model="editForm.value" placeholder="请输入配置值"></RichText>
					</el-form-item>
					<el-form-item v-else-if="editForm.type==3" label="配置值" prop="value" :rules="[{required: true,message: `配置值不能为空`,trigger: ['blur', 'change']}]">	
						<el-input v-model="editForm.value" type="number"
							placeholder="请输入配置值"></el-input>
					</el-form-item>
					<el-form-item v-else-if="editForm.type==4" label="配置值" prop="value">	
						<el-switch style="display: block" v-model="editForm.value"
							active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="2" active-text="开" inactive-text="关">
						</el-switch>
					</el-form-item>
					<el-form-item v-else-if="editForm.type==5" label="配置值" prop="value" :rules="[{required: true,message: `配置值不能为空`,trigger: ['blur', 'change']}]">
						<el-upload class="upload-demo" action="/admin/upload"
							:show-file-list="false" :on-success="handleFileSuccess"
							:before-upload="beforeFileUpload">
							<el-button size="small" type="primary">点击上传</el-button>
							<div slot="tip" class="el-upload__tip">只能上传文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item v-else-if="editForm.type==6" label="配置值" prop="value" :rules="[{required: true,message: `配置值不能为空`,trigger: ['blur', 'change']},{validator:$validate.checkPhone,trigger: ['blur', 'change']}]">
						<el-input v-model="editForm.value" type="number"
							placeholder="请输入配置值"></el-input>
					</el-form-item>
					<el-form-item v-else-if="editForm.type==7" label="配置值" prop="value" :rules="[{required: true,message: `配置值不能为空`,trigger: ['blur', 'change']}]">
						<el-upload class="avatar-uploader" action="/admin/upload"
							:show-file-list="false" :on-success="handleFileSuccess"
							:before-upload="beforeAvatarUpload">
							<img v-if="editForm.value" :src="editForm.value" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div class="el-upload__tip" slot="tip">只能上传图片文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="creating" @click="edit" v-if="editId"
							style="margin: 10px auto">编辑</el-button>
						<el-button type="primary" :loading="creating" @click="add" v-else style="margin: 10px auto">创建
						</el-button>
						<el-button @click="showEdit=false" style="margin: 10px">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		delToast,
		formVerify
	} from './../../utlis/decorators'
	import RichText from './../../components/RichText'
	export default {
		name: "configList",
		components: {
			RichText
		},
		data() {
			return {
				loading: false,
				creating: false,
				total: 0,
				showEdit: false,

				searchForm: {
					page: 1,
					limit: 10,
				},
				editForm: {
					type: '',
					value: '',
				},
				editId: '',
				tableData: [],
			}
		},
		created() {
			this.getList();
		},
		watch: {
			'searchForm.page'() {
				this.getList();
			},
		},
		methods: {
			//头像
			handleFileSuccess(res, file) {
				this.editForm.value = res.url;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type.indexOf('image/');
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (isJPG!=-1) {
				    this.$message.error('请上传图片格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
				}
				return isLt2M;
			},
			beforeFileUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传文件大小不能超过 2MB!');
				}
				return isLt2M;
			},
			handShowAdd() {
				this.editId = '';
				try {
					this.showEdit = true;
					this.$refs['forms'].resetFields()
					for (let i in this.editForm) {
						this.editForm[i] = '';
					}
				} catch {}
			},
			async handEditShow(row) {
				this.editId = row.id;
				for (let i in this.editForm) {
					this.editForm[i] = row[i];
				}
				this.showEdit = true;

			},
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			//获取列表
			async getList() {
				this.loading = true
				let [e, data] = await this.$api.getConfigListApi(this.searchForm);
				this.loading = false
				if (e) return;
				if (data.code === 200) {
					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},
			//添加
			@formVerify('forms')
			async add() {
				this.creating = true;
				let [e, data] = await this.$api.addArticleApi(this.editForm);
				if (e) {
					this.creating = false;
					return;
				}
				if (data.code === 200) {
					this.getList();
					this.showEdit = false;
					this.creating = false;
					this.$message.success('添加成功')
				} else {
					this.creating = false;
					this.$message.warning(`${data.msg}`)
				}
			},
			//编辑
			@formVerify('forms')
			async edit() {
				this.creating = true;
				let [e, data] = await this.$api.renewConfigApi({
					id: this.editId,
					value: this.editForm.value
				});
				if (e) {
					this.creating = false;
					return;
				}
				if (data.code === 200) {
					this.showEdit = false;
					this.creating = false;
					this.getList();
					this.$message.success('保存成功')
				} else {
					this.creating = false;
					this.$message.warning(`${data.msg}`)
				}
			}
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	/deep/ .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.bm-view {
		width: 800px;
		height: 400px;
	}

	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}
</style>
